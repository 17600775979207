import { CustomContainer } from '@/components/Container/Container.styles'
import LocalThemeProvider from '@/components/LocalThemeProvider'
import WrapperContentItemsContainer from '../../components/WrapperContentItemsContainer'
import SecondaryTitle from '../../components/SecondaryTitle'
import PrimaryTitle from '../../components/PrimaryTitle'

import CustomerNavigationSlider from './CustomerNavigationSlider'
import { useGetSectionTheme } from '@/hooks'
import { WrapperContentInterface } from '@/interfaces/wrapperContent'

const CustomerNavigationTab: React.FC<WrapperContentInterface> = (props) => {
  const {
    primaryTitle,
    secondaryTitle,
    theme,
    customTheme,
    itemList,
    withSeparator
  } = props
  
  const sectionTheme = useGetSectionTheme(theme, customTheme)

  return (
    <LocalThemeProvider theme={{ sectionTheme }}>
      <WrapperContentItemsContainer withSeparator={withSeparator}>
        <CustomContainer>
          {secondaryTitle && <SecondaryTitle>{secondaryTitle}</SecondaryTitle>}
          <PrimaryTitle>{primaryTitle}</PrimaryTitle>
          <CustomerNavigationSlider itemList={itemList} />
        </CustomContainer>
      </WrapperContentItemsContainer>
    </LocalThemeProvider>
  )
}

export default CustomerNavigationTab
