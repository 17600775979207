import { Arrow, ArrowContainer } from '@/components/LeadsWrapper/templates/LeadsPage/LeadsPage.styles'
import styled, { css } from 'styled-components'

export const SliderThumbnail = styled.div<{ animationSpeed: number }>`
  display: flex;
  align-items: flex-end;
  height: 100%;
  border-bottom: 2px solid transparent;
  padding-bottom: 12px;
  transition : border-bottom-color ${(props) => props.animationSpeed}ms ease-out;
  
  & > img {
    filter: grayscale(1);
    opacity: 0.5;
    max-width: 130px;
    height: 30px;
    display: block;
    margin: 0 auto;
    transition: all ${(props) => props.animationSpeed}ms ease-out;
    transition-property: filter, opacity;
  }
`

export const DesktopSliderWrapper = css`
  .slick-list {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .slick-slide.slick-active {
    z-index: 1;
  }

  .slick-slider {
    display: flex;
    flex-direction: column-reverse;
  }

  .slick-dots.slick-thumb {
    position: initial;
    display: flex !important;
    justify-content: space-evenly;
    margin-bottom: 60px;

    & > li {
      width: auto;
      height: auto;

      &:first-child {
        margin-left: 0;
      }

      &.slick-active ${SliderThumbnail} {
        border-bottom-color: ${({ theme }) =>
    theme.pageTheme?.main ?? theme.colors.orange};

        & > img {
          filter: grayscale(0%);
          opacity: 1;
        }
      }
    }
  }
`

export const MobileSliderWrapper = css`
  overflow: visible;

  .slick-track {
    display: flex;
  }

  // For showing the next slides
  .slick-list {
    overflow: visible;

    .slick-slide {
      height: auto;

      & > div {
        display: flex;
        height: 100%;
        padding: 0 15px;

        & > div {
          margin: 0 auto;
          width: 100%;
        }
      }

      &:not(.slick-current) {
        opacity: 1;
      }
    }
  }


  .slick-slide.slick-active.slick-current {
    z-index: 1;
  }

  // For chaging dots style
  .slick-prev:before,
  .slick-next:before {
    content: "" !important;
  }

  .slick-dots {
    font-size: 0;
    bottom: -30px;
    transform: translateY(100%);

    li,
    li button {
      width: 13px;
      height: 13px;
    }

    li {
      margin: 0 7.5px;

      &.slick-active button {
        background: ${({ theme }) => theme.sectionTheme?.text};
      }
    }

    li button {
      opacity: 1;
      border-radius: 50%;
      transition: .4s;
      border: solid 1px ${({ theme }) => theme.sectionTheme?.text};
      cursor: pointer;

      &:before {
        display: none;
      }
    }
  }
`

export const CustomerNavigationSliderWrapper = styled.div`
  ${({ theme }) => theme.mediaBreakpointDown.md} {
    && {
      ${MobileSliderWrapper}
      margin: 0 -30px 45px;
    }
  }
  
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    && {
      ${DesktopSliderWrapper}
      margin: unset;
    }
  }
`

const ARROW_SIZE = 46
const IMAGE_NAV_HALF_HEIGHT = 104 / 2 // (height of image wrapper + margin bottom) / 2

export const NextArrowContainer = styled(ArrowContainer)`
  right: -${ARROW_SIZE + 24}px;
  top: calc(50% + ${IMAGE_NAV_HALF_HEIGHT}px);
`

export const PrevArrowContainer = styled(ArrowContainer)`
  left: -${ARROW_SIZE + 24}px;
  top: calc(50% + ${IMAGE_NAV_HALF_HEIGHT}px);

  ${Arrow}, & > svg {
    transform: scaleX(-1);
  }
`
